import React from 'react';

import {
  CustomSection,
  ContainerElement,
  Header,
  HeaderWrapper,
  CircleShape,
  TriangleShape,
  HeaderTitle,
  TriangleShapeTwo,
  TriangleShapeThree,
  TriangleShapeFour,
  TriangleShapeFive,
} from './styles';

export default function ComingSoon() {
  return (
    <CustomSection>
      <ContainerElement>
        <Header>
          <HeaderWrapper>
            <CircleShape />
            <TriangleShape />
            <HeaderTitle $type="primary">
              Thank you for joining
              <br />
              <span>Ascend 2023</span>.<br />
              More to come soon!
            </HeaderTitle>
          </HeaderWrapper>
        </Header>
        <TriangleShapeTwo />
        <TriangleShapeThree />
        <TriangleShapeFour />
        <TriangleShapeFive />
      </ContainerElement>
    </CustomSection>
  );
}
