import ComingSoon from '@/components/coming-soon';
import { SEO } from '@/components/core';

export default function ComingSoonPage() {
  return (
    <>
      <SEO seo={{ title: 'Coming Soon', description: '', image: null }} preview />
      <ComingSoon />
    </>
  );
}
