import styled from 'styled-components';
import theme from '@/utils/styles-variables';
import { H2 } from '@/components/core/Title';
import { Container } from '@/components/core';
import Section from '@/components/core/Section';
import CircleShapeSVG from '@/public/assets/countdown-circle-shape.svg';
import TriangleShapeSVG from '@/public/assets/countdown-triangle-shape.svg';

export const CustomSection = styled(Section)`
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerElement = styled(Container)`
  position: relative;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  text-align: center;
  position: relative;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    max-width: 516px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    max-width: 70%;
  }
`;

export const HeaderTitle = styled(H2)`
  position: relative;
  z-index: 2;

  span {
    color: ${(props) => props.theme.colors.primary.default};
  }

  @media (min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px) {
    font-size: 45px;
    line-height: 56px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    font-size: 55px;
    line-height: 64px;
  }
`;

export const CircleShape = styled(CircleShapeSVG)`
  position: absolute;
  top: -83px;
  left: calc(50% - 93px);
  z-index: 1;
  width: 187px;
  height: 187px;
  opacity: 50%;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 277px;
    height: 277px;
    left: calc(50% - 272px);
    top: -123px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 490px;
    height: 490px;
    left: calc(50% - 490px);
    top: -173px;
  }
`;

export const TriangleShape = styled(TriangleShapeSVG)`
  position: absolute;
  left: calc(50% - 149px);
  top: 27px;
  width: 298px;
  height: 227px;
  opacity: 50%;
  z-index: 1;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 441px;
    height: 335px;
    left: calc(50% - 360px);
    top: 48px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 779px;
    height: 592px;
    left: calc(50% - 634px);
    top: 121px;
  }
`;

export const TriangleShapeTwo = styled(TriangleShapeSVG)`
  position: absolute;
  left: calc(50% - 329px);
  bottom: -85px;
  width: 298px;
  height: 226px;
  opacity: 50%;
  z-index: 1;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 441px;
    height: 335px;
    left: calc(50% - 500px);
    bottom: -129px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 779px;
    height: 591px;
    left: calc(50% - 906px);
    bottom: calc(-482px - 90px);
  }
`;

export const TriangleShapeThree = styled(TriangleShapeSVG)`
  position: absolute;
  left: calc(50% - 90px);
  bottom: -85px;
  width: 230px;
  height: 175px;
  opacity: 50%;
  z-index: 1;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 341px;
    height: 259px;
    left: calc(50% - 184px);
    bottom: -119px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 602px;
    height: 457px;
    left: calc(50% - 348px);
    bottom: calc(-489px - 90px);
  }
`;

export const TriangleShapeFour = styled(TriangleShapeSVG)`
  position: absolute;
  right: calc(50% - 240px);
  bottom: -68px;
  width: 247px;
  height: 187px;
  opacity: 50%;
  z-index: 1;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 365px;
    height: 277px;
    right: calc(50% - 305px);
    bottom: -78px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 644px;
    height: 489px;
    right: calc(50% - 544px);
    bottom: calc(-411px - 90px);
  }
`;

export const TriangleShapeFive = styled(TriangleShapeSVG)`
  position: absolute;
  right: calc(50% - 334px);
  bottom: -35px;
  width: 247px;
  height: 187px;
  opacity: 50%;
  z-index: 1;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 365px;
    height: 277px;
    right: calc(50% - 456px);
    bottom: -38px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 644px;
    height: 489px;
    right: calc(50% - 814px);
    bottom: calc(-321px - 90px);
  }
`;
